import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Landing } from '../landing'

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Home_Renovations_(Unsplash).jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Landing
        copy={
          <h1>
            We <br /> Are <br />
            Reliable
          </h1>
        }
        data={data}
      />
    </>
  )
}

export default Gallery
